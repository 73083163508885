<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4">Organisation</h1>
      <div class="w-7/12 mt-1 ml-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="mx-3 mt-4">
      <Card :loading="loadStats" :class="loadStats ? 'p-5' : 'px-16'">
        <div class="flex">
          <div class="py-5 px-4 flex">
            <icon icon-name="people" class-name="-mt-10" size="ms" />
            <div class="ml-6">
              <p
                class="text-jet text-sm font-semibold uppercase"
                style="width: 100px"
              >
                All People
              </p>
              <p class="text-jet font-extrabold -mt-2" style="font-size: 42px">
                {{ orgStats ? orgStats.total : "-" }}
              </p>
            </div>
            <div
              class="flex text-mediumSeaGreen mt-9"
              style="
                background-color: rgba(19, 181, 106, 0.15);
                height: 25px;
                padding: 2px;
              "
            >
              <Icon
                icon-name="chart-vector"
                class="xsm"
                style="color: #13b56a"
              />
              <p>{{ orgStats ? orgStats.totalPercent : "-" }}%</p>
            </div>
          </div>
          <div
            style="
              border: 0.5px dashed #878e99;
              width: 200px;
              height: 0;
              opacity: 0.5;
            "
            class="mx-3 my-auto"
          />
          <div class="py-5 px-4 flex">
            <icon icon-name="people" class-name="-mt-10" size="ms" />
            <div class="ml-6">
              <p
                class="text-jet text-sm font-semibold uppercase"
                style="width: 100px"
              >
                Employees
              </p>
              <p class="text-jet font-extrabold -mt-2" style="font-size: 42px">
                {{ orgStats ? orgStats.employeesCount : "-" }}
              </p>
            </div>
            <div
              class="flex text-mediumSeaGreen mt-9"
              style="
                background-color: rgba(19, 181, 106, 0.15);
                height: 25px;
                padding: 2px;
              "
            >
              <Icon
                icon-name="chart-vector"
                class="xsm"
                style="color: #13b56a"
              />
              <p>{{ orgStats ? orgStats.employeesPercent : "-" }}%</p>
            </div>
          </div>
          <div
            style="
              border: 0.5px dashed #878e99;
              width: 200px;
              height: 0;
              opacity: 0.5;
            "
            class="mx-3 my-auto"
          />
          <div class="py-5 px-4 flex">
            <icon icon-name="people" class-name="-mt-10" size="ms" />
            <div class="ml-6">
              <p
                class="text-jet text-sm font-semibold uppercase"
                style="width: 120px"
              >
                Non-Employees
              </p>
              <p class="text-jet font-extrabold -mt-2" style="font-size: 42px">
                {{ orgStats ? orgStats.noneEmployeesCount : "-" }}
              </p>
            </div>
            <div
              class="flex text-mediumSeaGreen mt-9"
              style="
                background-color: rgba(19, 181, 106, 0.15);
                height: 25px;
                padding: 2px;
              "
            >
              <Icon
                icon-name="chart-vector"
                class="xsm"
                style="color: #13b56a"
              />
              <p>{{ orgStats ? orgStats.noneEmployeesPercent : "-" }}%</p>
            </div>
          </div>
        </div>
      </Card>
    </div>
    <div>
      <div class="mx-3 mt-4">
        <Card class="mt-4 p-5">
          <div class="flex mb-6" style="justify-content: flex-end">
            <Button class="text-white" @click="initialState"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              Add New
            </Button>
          </div>
          <template v-slot:footer>
            <card-footer
              printcard
              reloadcard
              @print="print"
              show-selection
              @reload="reload()"
              @sortType="handleSort($event)"
              @option="handleOptionSelect($event)"
              @searchResult="handleSearch($event)"
            />
          </template>
        </Card>
      </div>
      <div v-if="!showError" class="p-3 mb-6">
        <sTable
          :headers="peopleHeader"
          :items="peopleData"
          style="width: 100%"
          :loading="loading"
          aria-label="people table"
          class="w-full"
          id="printMe"
          :pagination-list="metaData"
          @page="handlePage($event)"
          @itemsPerPage="handleItemsPerPage($event)"
          page-sync
          @filter="handleFilter($event)"
          has-number
        >
          <template v-slot:item="{ item }">
            <div v-if="item.photo">
              <img
                class=""
                :src="item.data.photo"
                alt="profile picture"
                v-if="item.data.photo"
                style="height: 30px; width: 30px; border-radius: 5px"
              />
              <div
                style="height: 35px; width: 35px; border-radius: 5px"
                class="text-blueCrayola border text-center font-semibold pt-2"
                v-else
              >
                {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
              </div>
            </div>
            <span
              class="font-bold"
              style="color: #525252"
              v-else-if="item.fname"
            >
              {{ item.data.fname }} {{ item.data.lname }}
            </span>
            <span v-else-if="item.orgFunction">
              {{ !item.data.orgFunction ? "Nil" : item.data.orgFunction.name }}
            </span>
            <span v-else-if="item.roles">{{
              !item.data.roles.length ? "Nil" : item.data.roles[0].name
            }}</span>
            <span v-else-if="item.isEmployee">
              <div
                class="p-1 mr-2 account"
                :class="[
                  item.data.isRoot
                    ? 'account_owner'
                    : item.data.isEmployee
                    ? 'account_user'
                    : 'account_unassigned',
                ]"
              >
                {{
                  item.data.isRoot ? "Owner" : item.data.isEmployee ? "Employee" : "Unassigned",
                }}
              </div>
            </span>
            <span v-else-if="item.createdAt">{{
              $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
            }}</span>
            <span v-else-if="item.isActive">
              <div
                class="activeTyp p-1"
                :class="[
                  item.data.isActive
                    ? 'activeType_active'
                    : 'activeType_inactive',
                ]"
              >
                {{ item.data.isActive ? "Active" : "Inactive" }}
              </div>
            </span>
            <span v-else-if="item.auth"
              >{{
                item.data.auth
                  ? item.data.auth.lastLogin
                    ? $DATEFORMAT(
                        new Date(item.data.auth.lastLogin),
                        "MMMM dd,yyyy"
                      )
                    : "Nil"
                  : "Nil"
              }}
            </span>

            <span v-else-if="item.id">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                <div style="width: 116px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="handleEdit(item.data)"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Edit</p>
                  </div>
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="handleDelete(item.data.id)"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-flame mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Delete</p>
                  </div>
                </div>
              </Menu>
            </span>
          </template>
        </sTable>
      </div>
      <div v-else>
        <error-page />
      </div>
    </div>
    <create-people
      :open-modal="openModal"
      :title="editMode ? 'Edit Person' : 'Create New Person'"
      :is-edit="editMode"
      :clear-data="clearData"
      :people-data="peopleDetails"
      @submit="handleSubmit($event)"
      @close="(openModal = false), (clearData = !clearData)"
    />
    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this User?
          </p>
          <div class="flex">
            <Button class="text-white" @click="deletePerson"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import CardFooter from "@/components/CardFooter";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";
import Button from "@/components/Button";
import Menu from "@/components/Menu";

import Modal from "@/components/Modal";

export default {
  name: "People",
  components: {
    Button,
    Card,
    Breadcrumb,
    ErrorPage,
    CardFooter,
    Menu,
    STable,
    Modal,
    CreatePeople: () => import("@/components/CreatePeople"),
  },
  data() {
    return {
      disableBtn: false,
      bgButton: "bg-flame",
      dialogDel: false,
      peopleData: [],
      clearData: false,
      email: "",
      error: [],
      openModal: false,
      timeout: null,
      editMode: false,
      showError: false,
      sortType: null,
      isActive: null,
      searchWord: null,
      isEmployee: null,
      userId: "",
      itemsPerPage: null,
      numberOfPage: null,
      metaData: {},
      functionIds: [],
      loading: true,
      loadingData: false,
      functions: [],
      peopleDetails: null,
      designations: [],
      designationData: [],
      allOrgUsers: [],
      roleSelect: [],
      levelsArray: [],
      levelName: "",
      breadcrumbs: [
        {
          disabled: false,
          text: "Organisation",
          id: "",
        },
        {
          disabled: false,
          text: "People",
          id: "People",
        },
      ],
    };
  },

  methods: {
    async handleDelete(id) {
      try {
        await this.$handlePrivilege('people','deletePerson')
        this.userId = id;
        this.dialogDel = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async deletePerson() {
      try {
        await this.$_deleteUser({ userId: this.userId });
        this.dialogDel = false;
        this.fetchPeople("disengagedEmployees=false&isEmployee=false");
        this.$toasted.success("User deleted successfully", {
          duration: 5000,
        });
      } catch (error) {
        this.dialogDel = false;
        this.$toasted.error(`This user is an employee and cannot be deleted.`, {
          duration: 6000,
        });
      }
    },
    handleSubmit(data) {
      this.userDetails = data;
      if (this.editMode) {
        this.updatePerson();
      } else {
        this.submit();
      }
    },
    async initialState() {
      try {
        await this.$handlePrivilege("people", "addNewPerson");
        this.editMode = false;
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleEdit(data) {
      this.peopleDetails = null;
      try {
        await this.$handlePrivilege("people", "editPerson");
        this.clearData = false;
        this.peopleDetails = data;
        this.openModal = true;
        this.editMode = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    checkPermission() {
      if (
        this.userDetails.permission.includedLocationIds === null &&
        this.userDetails.permission.includedFunctionIds === null &&
        this.userDetails.permission.includedLevelIds === null &&
        this.userDetails.permission.excludedUserIds === null
      ) {
        this.userDetails.permission = null;
      }
    },
    async updatePerson() {
      this.disableBtn = true;
      this.bgButton = "bg-fadeFlame";
      this.checkPermission();
      try {
        await this.$_updateUser({
          payload: this.userDetails,
          userId: this.userDetails.id,
        });
        this.$toasted.success("User updated successfully", {
          duration: 3000,
        });
        this.openModal = false;
        this.disableBtn = false;
        this.bgButton = "bg-flame";
        this.$store.dispatch("org/getOrgStats");
        this.fetchPeople("disengagedEmployees=false&isEmployee=false");
      } catch (error) {
        this.$toasted.error(`${error.message}`, {
          duration: 3000,
        });
        this.disableBtn = false;
        this.bgButton = "bg-flame";
        throw new Error(error);
      }
    },
    submit() {
      this.checkPermission();
      const payLoad = this.userDetails;
      this.disableBtn = true;
      this.bgButton = "bg-fadeFlame";
      this.$_createUser(payLoad)
        .then((result) => {
          this.$toasted.success(`${result.data.message}`, { duration: 3000 });
          this.openModal = false;
          this.$store.dispatch("org/getOrgStats");
          this.fetchPeople("disengagedEmployees=false&isEmployee=false");
          this.disableBtn = false;
          this.bgButton = "bg-flame";
        })
        .catch((err) => {
          this.$toasted.error(`${err.message}`, { duration: 3000 });
           this.userDetails.permission = {}
          this.userDetails.permission.includedLocationIds = null
          this.userDetails.permission.includedFunctionIds = null
          this.userDetails.permission.includedLevelIds = null
          this.userDetails.permission.excludedUserIds = null
          this.disableBtn = false;
          this.bgButton = "bg-flame";
        });
    },
    queryUpdate() {
      const isActive = this.isActive ? `&isActive=${this.isActive}` : "";
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      const functionIds = this.functionIds.length
        ? `&functionId=${this.functionIds.join(",")}`
        : "";
      this.fetchPeople(
        `disengagedEmployees=false&isEmployee=false${isActive}${sort}${pageNumber}${itemPage}${search}${functionIds}`
      );
    },
    checkIfPresent(item, itemArray) {
      const itemIndex = itemArray.indexOf(item);
      if (itemIndex >= 0) {
        itemArray.splice(itemIndex, 1);
      } else {
        itemArray.push(item);
      }
      return itemArray;
    },
    handleFilter(filterObject) {
      this.loading = true;
      const { value, id } = filterObject;
      switch (value) {
        case "orgFunction":
          this.functionIds = this.checkIfPresent(id, this.functionIds);
          return this.queryUpdate();

        default:
          this.functionIds = [];
          this.designationIds = [];
          return this.fetchPeople("disengagedEmployees=false&isEmployee=false");
      }
    },
    handlePage(pageNumber) {
      this.loading = true;
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleItemsPerPage(itemNo) {
      this.loading = true;
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      this.loading = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.loading = true;
      this.sortType = sortType;
      this.queryUpdate();
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    reload() {
      this.loading = true;
      this.fetchPeople("disengagedEmployees=false&isEmployee=false");
    },
    async fetchPeople(payload) {
      this.loading = true;
      try {
        const { data } = await this.$_getAllOrgUsersWithOptions(payload);
        this.peopleData = data.users;
        this.metaData = data.meta;
        const allOrgUsers = data.users.map((item) => ({
          id: item.id,
          name: `${item.fname} ${item.lname}`,
        }));
        this.allOrgUsers = allOrgUsers;
        this.loading = false;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  computed: {
    filteredFunctions() {
      return this.$store.state.org.getAllFunctions;
    },
    ...mapState({
      orgStats: (state) => state.org.getOrgStats,
      loadStats: (state) => state.org.loading,
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    setDesignation() {
      const findDesignation = this.$store.state.org.getAllFunctions.find(
        (item) => item.id === this.userDetails.functionId
      );
      if (findDesignation) {
        return findDesignation.designations;
      }

      return [];
    },
    peopleHeader() {
      return [
        { title: "", value: "photo", width: 6 },
        { title: "NAME", value: "fname", width: 15 },
        { title: "EMAIL ADDRESS", value: "email", width: 15 },
        {
          title: "USER FUNCTION",
          value: "orgFunction",
        },
        { title: "ROLE", value: "roles" },
        { title: "User Profile", value: "isEmployee" },
        { title: "DATE ADDED", value: "createdAt" },
        { title: "Last Login", value: "auth" },
        { title: "Status", value: "isActive" },
        { title: "", value: "id", image: true },
      ];
    },

  },
  async mounted() {
    try {
      await this.$handlePrivilege("people", "viewRegister");
      this.showError = false
      this.fetchPeople("disengagedEmployees=false&isEmployee=false");
      this.$store.dispatch("org/getOrgStats");
    } catch (error) {
      this.showError = true
      this.loading = false
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-radius {
  border-radius: 7px;
}
.account {
  border-radius: 5px;
  width: fit-content;
  &_user {
    background: rgba(47, 128, 237, 0.15);
    color: #2176ff;
  }
  &_owner {
    background: rgba(234, 206, 227, 0.15);
    color: #f15a29;
  }
  &_unassigned {
    background: rgba(235, 87, 87, 0.15);
    color: #ea3c53;
  }
}
.activeType {
  border-radius: 5px;
  width: fit-content;
  &_active {
    background: rgba(19, 181, 106, 0.08);
    color: #13b56a;
  }
  &_inactive {
    background: rgba(235, 87, 87, 0.15);
    color: #ea3c53;
  }
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
